import { Api } from '../resources/api-constants'
import CustomAxios from '../utility/customAxios'
import { PaginationExtractor } from '../utility/helpers/pagination-extractor'

export const loginService = (body: any) => {
	return CustomAxios.post(Api.LOGIN_LOCAL, body)
}

export const loginWithGoogleCallBackService = (data: any) => {
	return CustomAxios.get(Api.LOGIN_GOOGLE_CALLBACK + PaginationExtractor(data))
}

export const signupService = (body: any) => {
	return CustomAxios.post(Api.SIGNUP_LOCAL, body)
}

export const createUserSettingsService = (body: any) => {
	return CustomAxios.post(Api.CREATE_SETTINGS, body)
}

export const updateUserSettingsService = (body: any) => {
	return CustomAxios.put(Api.UPDATE_SETTINGS, body)
}
