import { createAsyncThunk } from '@reduxjs/toolkit'
import {
	createUserSettingsService,
	loginService,
	loginWithGoogleCallBackService,
	signupService,
	updateUserSettingsService
} from '../../../services/auth.service'

export const loginAction = createAsyncThunk('loginAction/post', async (data: any, thunkApi) => {
	try {
		const response = await loginService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const loginWithGoogleCallbackAction = createAsyncThunk('loginWithGoogleCallbackAction/get', async (data: any, thunkApi) => {
	try {
		const response = await loginWithGoogleCallBackService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const signupAction = createAsyncThunk('signupAction/post', async (data: any, thunkApi) => {
	try {
		const response = await signupService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const createUserSettingsAction = createAsyncThunk('createUserSettingsAction/post', async (data: { firebaseToken: string }, thunkApi) => {
	try {
		const response = await createUserSettingsService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const updateUserSettingsAction = createAsyncThunk('updateUserSettingsAction/post', async (data: { firebaseToken: string }, thunkApi) => {
	try {
		const response = await updateUserSettingsService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})
