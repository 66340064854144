import { createAsyncThunk } from '@reduxjs/toolkit'
import { cancelOrderService, createNewOrderService, getUserOrdersService, validatePromoCodeService } from '../../../services/checkout.service'
import { IPagination } from '../../../utility/interface/pagination.interface'

export const createNewOrderAction = createAsyncThunk('createNewOrderAction/post', async (data: any, thunkApi) => {
	try {
		const response = await createNewOrderService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const validatePromoCodeAction = createAsyncThunk('validatePromoCodeAction/post', async (data: {promo: string, kitchenId: string}, thunkApi) => {
	try {
		const response = await validatePromoCodeService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const getUserOrdersAction = createAsyncThunk('getUserOrdersAction/post', async (data: IPagination, thunkApi) => {
	try {
		const response = await getUserOrdersService(data)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})

export const cancelOrderAction = createAsyncThunk('cancelOrderAction/post', async (id: string, thunkApi) => {
	try {
		const response = await cancelOrderService(id)
		return response.data
	} catch (error: any) {
		return thunkApi.rejectWithValue(error.response.data)
	}
})
